import { CONSOLE_BACKGROUND, ENABLE_LOGGING } from './constants';
import { logEvent, logMessage } from '../helpers/logging';

const sendIdentify = (clientId) => {
	if (!window || !window.ire) {
		logMessage(
			'Impact',
			CONSOLE_BACKGROUND,
			ENABLE_LOGGING,
			'identify',
			'no window.ire.identify function found',
		);

		return;
	}

	window.ire('identify', { customerId: clientId, customerEmail: '' });

	logEvent('Impact', CONSOLE_BACKGROUND, ENABLE_LOGGING, 'identify', {
		customerId: clientId,
		customerEmail: '',
	});
};

export default sendIdentify;
